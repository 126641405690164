
.nav{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height:60vh;
  background-color: rgba(0,0,0,0);

  justify-content: center;
  align-items: center;

}
.navitem{
  margin: 0 7px;
  color: white;
  text-decoration: none;
}
.navhome{
  margin-left: 0;
}
.navlist{
list-style-type: none;
display: flex;
margin-top: 8px;
font-size: 4vw;
}
.logodiv{
  height: 53vh;
  width: 55vw;
  margin-top: 18px;
  margin-bottom: 10px;
  /* box-shadow: 10px 10px 10px 10px black; */
 }
.logoimg{
  width: 100%;
  height: auto;
  max-height: 100%;
  /* box-shadow: 0 0 5px 10px #555 ; */
  box-shadow: -5px -5px 40px 40px rgba(0,0,0,0) inset;

}
body{
  background-color: #c2eaba;
  background-image: linear-gradient(319deg, #c2eaba 0%, #c5f4e0 37%, #efc2cf 100%);
  height: 100%;
}
html,body{
margin: 0;
padding: 0;
overflow-x: hidden;
}
.container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.homecontainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}
.jkydiv{
  width: 100vw;
  display: flex;
  padding: 0;
  justify-content: space-between;
}
.jkyleft{
  width:45%;

}
.jkyright{
  width: 45%;

}
.softplaykidshotdiv{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 19px;
}
.softplaykidshot{
  width: 56%;
  height: auto;
  max-height: 100%;
}

.webtitlediv{
  width: 97vw;
  height: 14vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.softplaywebtitle{
  height: 150vh;
}
.title-text{
  text-align: center;
  font-size: 5.5vw;
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, #cdedfd 0%, #ffec82 37%, #ffcfd2 100%);
  -webkit-text-stroke: 2px black;

}
.titleparagraphdiv{
  width: 71vw;
}
.titleparagraph{
  line-height: 2.9rem;
  font-size: 2.8vw;
  text-align: center;
  font-family: 'Open Sans';
}
.threecolumnsec{
  width: 116%;
}
.row{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.row:after{
  content: "";
  display: table;
  clear: both;
}
.firstc{
  border-right: 2px solid white;
}
.midc{
  margin: 0 19px;
}
.lastc{
  border-left: 2px solid white;
}
.column{
  display: flex;
flex-direction: column;
align-items: center;
/* float: left; */
width: 31.33%;
height: 47vh;
color: white;


}
.clientsloveh1{
  text-align: center;
  font-size: 4.5vw;
}
.quotecharacter{
  color: yellow;
  font-size: 6vw;
  margin: 0 auto;
  margin-bottom: -65px;
}
.quotediv{
  width: 71vw;
  margin: 0 auto;
  padding-left: 37px;
  font-size: 3vw;
  font-family: 'Open Sans';
  font-style: italic;
}



.aboutdiv{
  height: 66vh;
}

.abouttitle{
  text-align: center;
}
.paragraph{
  font-family: 'Open Sans';
  font-size: 2.3vw;
  text-align: center;
}
.package{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2px 0;
  width: 30vw;
  /* align-self: flex-start; */
}
.packagetitle{
  text-align: center;
  font-family: 'Open Sans';
}
.packagedes{
  font-size: 1.7vw;
  text-align: center;
  font-family: 'Open Sans';
}
.pricingsecdiv{
  width: 93vw;
  display: flex;
  flex-direction: column;
  /* border: 1px solid black; */
  padding: 0;
}
.l{
  align-self: flex-start;
}
.r{
  align-self: flex-end;
}
.m{
  align-self: center;
}
.pricepageimg{
  width: 100%;
}
.inquiryheadlist li{
  font-size: 1.3vw;
  font-family: 'Open Sans';
}
.upperpackagedescription{
  width: 77vw;
  height: 8vh;
  display: flex;
  justify-content: space-between;
}
.lowerpackagedescription{
  width: 55vw;
}
.packagepricediv{
  display: flex;
}
.inputbar{
  width: 70vw;
  height: 4vh;
  border-radius: 7px;
  /* border-right: none; */
  /* border-top-right-radius: 0; */
}
.inputbar:hover{
  border: 1px solid blue;
}
.label{
  font-size: 1.6vw;
  font-family: 'Open Sans'
}
.sp{
  color: red;
}
.pp{
  color: yellow;
}
.dp{
  color: blue;
}
.lp{
  color: white;
}
.ap{
  color: green;
}
.googlecal{
  width: 600px;
  height: 400px;
}
@media (max-width: 500px) {
  .logodiv{
    margin-top: 40px;
    width: 24vw;
    height: 36vh;
    margin-bottom: -30px;
  }
  .homecontainer{
    height: 100%;
  }
  .softplaykidshotdiv{
    width: 100vw;
  }
  .title-text{
    font-size: 7vw;
  }
  .titleparagraphdiv{
    width: 97vw;
  }
  .threecolumnsec{
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
  .column{
    width: 97vw;
  }
  .quotecharacter{
    margin-bottom: 0;
    align-self: flex-start;
  }
  .pricingtitle{
    text-align: center;
  }
  .pricingsecdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .package{
    width: 97vw;
  }
  .packagedes{
    font-size: 7.5vw;
  }
  .l{
    align-self: center;
  }
  .r{
    align-self: center;
  }
  .aboutp{
    font-size: 7.5vw;
  }
  .aboutdiv{
    height: 100%;
  }
  .inquiryformcontainer{
    display: flex;
    font-size: 8.5vw;
    flex-direction: column;
    align-items: center;
  }
  .inquirylistul{
    width: 96vw;

  }
  .inquiryheadlist{
    font-size: 4vw;
    display: inline-block;
    width: 93vw;
    margin-bottom: 26px;
    text-align: center;

  }
  form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 97vw;
  }
  .inputbar{
    align-self: center;
    text-align: center;
  }
  .label{
    font-size: 6vw;
  }
  .inquiryformbackground{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .packagepricediv{
    height: 86vh;
    width: 100vw;
  }
  .upperpackagedescription{
    height: 20vh;
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;
    width: 95vw;
    padding-right: 50px;
  }
  .lowerpackagedescription{
    height: 76vh;
    display: flex;
    justify-content: flex-start;
    width: 94vw;
  }

}
